import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LoadingOutlined, MailOutlined, PhoneFilled } from "@ant-design/icons";
import { getAddressString } from "@state/gazprofessional/dto/gaz.professional.item.details.dto";
import BasicButton from "@components/buttons/BasicButton";
import MapLeaflet from "@components/map/MapLeaflet";
import { Spin } from "antd";
import { Link } from "react-router-dom";
import Section from "@components/section/Section";
import GazEventIndividualActionCard from "@components/cards/GazEventIndividualActionCard";
import GazEventIndividualPromotionCard from "@components/cards/GazEventIndividualPromotionCard";
import Slider, { Settings } from "react-slick";

import { useStore } from "effector-react";
import { authentifiedStore } from "@state/auth/AuthStore";
import { prependHttps } from "@utils/history";
import configuration from "@utils/Config";
import { requestGetResource } from "@state/resource/ResourceEffects";
import { getBase64UrlFrom } from "@utils/Base64File";
import { ROUTES } from "@utils/Routes";
import { GazProfessionalUser } from "@type/auth/auth.types";
import { getGazEvent, getOptionPartner } from "@utils/gazEvent-utils";
import { ACTION_TYPE } from "@utils/enums/gaz.month.action.type.enum";

interface ProfessionalItemProps {
  professional: GazProfessionalUser | undefined;
  inContactList?: boolean;
  canAddToContacts?: boolean;
  onToggleContact?: () => void;
}

const getInterventionTypeFrom = (professional: GazProfessionalUser): string => {
  if (professional.installation && professional.maintenance) {
    return "Installation, Maintenance";
  } else if (professional.installation) {
    return "Installation";
  } else if (professional.maintenance) {
    return "Maintenance";
  } else {
    return "";
  }
};

interface Pictures {
  id: string;
  content: string;
}

const settings = {
  dots: false,
  arrows: true,
  draggable: false,
  infinite: false,
  slidesToShow: 2.25,
  slidesToScroll: 2,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1399,
      settings: {
        slidesToShow: 2.05,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 1199,
      settings: {
        slidesToShow: 1.65,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 1.23,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const settingsPicture: Settings = {
  dots: false,
  arrows: true,
  draggable: false,
  infinite: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,
  autoplay: true,
  autoplaySpeed: 3000,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const ProfessionalDetails: FunctionComponent<ProfessionalItemProps> = ({
  professional,
}) => {
  const { t } = useTranslation();
  const authentifiedContext = useStore(authentifiedStore);
  const isGazEvent = configuration.isGazEvent;
  const gazEventType = configuration.gazEventType;
  let eventParticipation;

  if (getGazEvent().typeEvent === "gazMonth") {
    eventParticipation = professional?.gazEvent?.gazMonth;
  } else if (getGazEvent().typeEvent === "rentreeDuChauffage") {
    eventParticipation = professional?.gazEvent?.rentreeDuChauffage;
  } else {
    eventParticipation = false;
  }

  const spinIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const sortList = ["PAC", "THPE", "POELE", "REGULATION"];
  const actions = professional?.gazEvent?.actions ?? [];

  const sortActions = [...actions].sort((a, b) => {
    return sortList.indexOf(a.actionType) - sortList.indexOf(b.actionType);
  });

  const pictureListId = professional?.pictureListId;
  const [picturesContent, setPicturesContent] = useState<Pictures[]>([]);

  useEffect(() => {
    if (pictureListId) {
      pictureListId.forEach((pictureId) => {
        void requestGetResource(pictureId);
      });
    }
  }, [pictureListId]);

  useEffect(() => {
    return requestGetResource.done.watch(({ result, params }) => {
      if (result.ok && result.data) {
        const resourceData = result.data;
        setPicturesContent((currentList) => [
          ...currentList,
          { id: params, content: resourceData.data },
        ]);
      }
    });
  });

  return (
    <div className="app-professional-details">
      {professional ? (
        <div className="content-contained">
          <div className="d-flex flex-column flex-md-row justify-content-between">
            <div className="professional-details-description mb-4 mb-md-0">
              <h2 className="professional-details-name">
                {professional.companyName}
              </h2>
              <div className="professional-details-interventions">
                {getInterventionTypeFrom(professional)}
              </div>
              <div className="professional-details-description-description">
                {professional.description}
              </div>
              <div>
                <div className="professional-details-info">
                  <img
                    className="anticon"
                    src="/img/pro/geoloc.svg"
                    alt={t<string>(
                      "pro.mentions.evapdc.detail.noCertificate.formation.detail.priceAlt",
                    )}
                  />
                  {getAddressString(professional) ?? ""}
                </div>
                {professional.editablePhone1 && (
                  <a
                    className="professional-details-info"
                    href={`tel:${
                      professional.editablePhone1 || professional.phone1
                    }`}
                  >
                    <PhoneFilled />
                    {professional.editablePhone1 || professional.phone1}
                  </a>
                )}
                {professional.editablePhone2 && (
                  <a
                    className="professional-details-info"
                    href={`tel:${professional?.editablePhone2}`}
                  >
                    <PhoneFilled />
                    {professional.editablePhone2}
                  </a>
                )}
                <a
                  className="professional-details-info"
                  href={`mailto:${
                    professional.editableEmail || professional.email
                  }`}
                >
                  <MailOutlined />
                  {professional.editableEmail || professional.email}
                </a>
                {professional.website && (
                  <a
                    className="professional-details-info"
                    href={prependHttps(professional.website)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="anticon"
                      alt={t<string>(
                        "gazprofessional.icons.network.information",
                      )}
                      src={"/img/icons/icon-network.svg"}
                    />
                    {t<string>("gazprofessional.icons.network.text")}
                  </a>
                )}

                <div className="icons d-flex align-items-center mx-2">
                  {professional.facebook && (
                    <a
                      className="icon"
                      href={prependHttps(professional.facebook)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        alt={t<string>("gazprofessional.icons.facebook")}
                        src={"/img/icons/facebook-individual-v2.svg"}
                      />
                    </a>
                  )}
                  {professional.twitter && (
                    <a
                      className="icon"
                      href={prependHttps(professional.twitter)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        alt={t<string>("gazprofessional.icons.twitter")}
                        src={"/img/icons/twitter-individual-v2.svg"}
                      />
                    </a>
                  )}
                  {professional.linkedin && (
                    <a
                      className="icon"
                      href={prependHttps(professional.linkedin)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        alt={t<string>("gazprofessional.icons.linkedin")}
                        src={"/img/icons/linkedin-individual-v2.svg"}
                      />
                    </a>
                  )}
                </div>
                <div className="mentions d-flex align-items-center mx-2">
                  {professional.pacHybride?.expiredAt && (
                    <div className="mention">
                      <img
                        src="/img/icons/certif-pac-hybride.svg"
                        alt="Expert PAC hybride"
                      />
                    </div>
                  )}
                  {professional.smokeDuct?.status === "ACCEPTED" && (
                    <div className="mention">
                      <img
                        src="/img/icons/certif-smoke-duct-v2.svg"
                        alt="Expert Conduit de fumée"
                      />
                    </div>
                  )}
                  {professional.greenGaz && (
                    <div className="mention">
                      <img
                        src="/img/icons/certif-green-gaz.svg"
                        alt="Engagé gaz vert"
                      />
                    </div>
                  )}
                </div>
              </div>
              <BasicButton
                text={t<string>("gazprofessional.details.contact")}
                className="mt-3 mr-3"
              />
              <div className="mt-3 mr-3">
                <Link to={ROUTES.professional.auth.login.generate()}>
                  {t<string>("gazprofessional.details.link1")}
                  <br />
                  {t<string>("gazprofessional.details.link2")}
                </Link>
              </div>
            </div>
            <div className="professional-details-map">
              {professional.latitude > 0 && professional.longitude > 0 && (
                <div className="map flex-grow-1 d-flex flex-column">
                  <MapLeaflet
                    center={[professional.latitude, professional.longitude]}
                    markers={[
                      {
                        id: "marker",
                        position: [
                          professional.latitude,
                          professional.longitude,
                        ],
                      },
                    ]}
                    scrollWheelZoom
                    onMapZoomEnd={() => void 0}
                    onMapDragEnd={() => void 0}
                    onMouseUp={() => void 0}
                    zoom={14}
                  />
                </div>
              )}
            </div>
          </div>
          {picturesContent.length > 0 && (
            <div className="slider-container ">
              <Slider {...settingsPicture}>
                {picturesContent.map((picture, index) => (
                  <div
                    key={index.valueOf()}
                    className="partners-img d-flex align-items-center justify-content-center px-4"
                  >
                    <img
                      src={getBase64UrlFrom(picture.content, "PNG")}
                      alt={"photo"}
                      className="max-w-100"
                    />
                  </div>
                ))}
              </Slider>
            </div>
          )}
          {(isGazEvent || authentifiedContext.user?.id === professional.id) &&
            eventParticipation && (
              <div className="mt-40 pt-20">
                <Section
                  title={t<string>(
                    `${gazEventType}.individual.professional.actions.title`,
                  )}
                  className="mb-40"
                >
                  <>
                    {actions.length > 0 && (
                      <div className="slider-container slider-align-left">
                        <Slider {...settings} className="mb-20">
                          {sortActions.map((action, index) => (
                            <GazEventIndividualActionCard
                              key={index.valueOf()}
                              title={action.title
                                .concat(": ")
                                .concat(
                                  action.partner
                                    .map(
                                      (part) =>
                                        Object.values(
                                          getOptionPartner(
                                            ACTION_TYPE[action.actionType],
                                          ),
                                        ).filter((p) => p.value === part)[0]
                                          .label,
                                    )
                                    .join(", "),
                                )}
                              description={
                                action.description
                                  ? action.description
                                  : t<string>(
                                      `${gazEventType}.individual.professional.actions.description`,
                                    )
                              }
                              className="me-md-3"
                            />
                          ))}
                        </Slider>
                      </div>
                    )}
                    {isGazEvent && (
                      <GazEventIndividualPromotionCard
                        img="/img/pro/grdfLogo.svg"
                        title={t<string>(
                          `${gazEventType}.individual.professional.connection.title`,
                        )}
                        promotionFrom="2023-10-01"
                        promotionTo="2023-11-30"
                        description={t<string>(
                          `${gazEventType}.individual.professional.connection.description`,
                        )}
                        className="mb-20"
                      />
                    )}
                  </>
                </Section>
                {isGazEvent && (
                  <div className="mt-40 small">
                    {t<string>(
                      `${gazEventType}.individual.professional.connection.mention`,
                    )}
                  </div>
                )}
              </div>
            )}
        </div>
      ) : (
        <div className="spin-container">
          <Spin indicator={spinIcon} />
        </div>
      )}
      {/*{professional && (*/}
      {/*  <BasicModal*/}
      {/*    className="large-modal"*/}
      {/*    title={t<string>("search.contacts.form.title")}*/}
      {/*    open={isContactModalVisible}*/}
      {/*    onCancel={() => setContactModalVisible(false)}*/}
      {/*    footer={null}*/}
      {/*  >*/}
      {/*    <ProfessionalContactForm*/}
      {/*      originForm={ContactEnum.FICHE}*/}
      {/*      initSearchOption={{}}*/}
      {/*      gazProfessional={professional}*/}
      {/*      setContactModalVisible={setContactModalVisible}*/}
      {/*    />*/}
      {/*  </BasicModal>*/}
      {/*)}*/}
    </div>
  );
};

export default ProfessionalDetails;
