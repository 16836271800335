import { FC } from "react";
import PageLayout from "@components/layouts/PageLayout";
import HomeStepper from "@components/home/HomeStepper";
import HomeImageSection from "@components/home/HomeImageSection";
import { useTranslation } from "react-i18next";
import ImageModuleCMS from "@components/cms/ImageModuleCMS";
import ImageGroupModuleCMS from "@components/cms/ImageGroupModuleCMS";
import configuration from "@utils/Config";
import PartnersSection from "@components/section/PartnersSection";
import VideoCarousel from "@components/block/VideoCarousel";
import HomeHeaderGazMonth from "@components/home/HomeHeaderGazMonth";
import { ROUTES } from "@utils/Routes";
import HomeHeader from "@components/home/HomeHeader";
import NewsHeader from "@components/home/NewsHeader";
import HomeHeaderWithVideo from "@components/home/HomeHeaderWithVideo";

const HomeScreen: FC = () => {
  const { t } = useTranslation();
  const isGazEvent = configuration.isGazEvent;
  const frontendBaseUrl = configuration.frontendBaseUrl;
  const isHomeHeaderWithVideo = true;

  return (
    <PageLayout bodyClassName={"app-home"}>
      <div>
        {isHomeHeaderWithVideo ? (
          <HomeHeaderWithVideo
            videoUrl={`${frontendBaseUrl}/files/videos/pub_gazvert_2024_05.mp4`}
            posterUrl="/img/home/discover-video-thumbnail-pub.svg"
          />
        ) : isGazEvent ? (
          <HomeHeaderGazMonth />
        ) : (
          <HomeHeader noDescription={isGazEvent} fullWidth={isGazEvent} />
        )}
        {isGazEvent && <PartnersSection />}

        <div className="my-40">
          <VideoCarousel
            videoUrls={[
              `${frontendBaseUrl}/files/videos/pub_pg_3_en_1_720p.mp4`,
            ]}
            backgroundColor={"white"}
            sideContent={t<string>(
              "home.discover-gaz-professionals.side-content",
            )}
            alignImgLeft
            posterUrl="/img/home/discover-video-thumbnail.svg"
            fillContainer
            autoPlay={false}
            buttonAction={{
              buttonLabel: "En savoir plus",
              url: "https://lesprofessionnelsdugaz.com/particulier/les-professionnels-du-gaz/qu-est-ce-qu-un-professionnel-du-gaz ",
              variant: "primary",
            }}
            buttonAlignment={"start"}
          />
        </div>

        <HomeImageSection
          text={t<string>("home.why-section.side-content")}
          buttonAction={{
            buttonLabel: t<string>("home.why-section.button.label"),
            url: ROUTES.individual.gazProfessionals.search.generate(),
          }}
        />
        <div className="home-commitments my-40">
          <ImageGroupModuleCMS
            title={t<string>("home.commitments.title")}
            titleClassName="h1 text-center text-sm-start"
            images={[
              {
                src: "/img/home/local.svg",
                title: " ",
                description: t<string>("home.commitments.local"),
              },
              {
                src: "/img/home/audit.svg",
                title: " ",
                description: t<string>("home.commitments.audit"),
              },
              {
                src: "/img/home/quality.svg",
                title: " ",
                description: t<string>("home.commitments.quality"),
              },
            ]}
          />
        </div>
        <HomeStepper />
        <div className="my-40 pt-20 pb-20">
          <NewsHeader />
        </div>
        <div className="app-home-image-section my-40 pb-20 pt-0">
          <ImageModuleCMS
            src={"/img/home/help.png"}
            alt={"Aide"}
            sideContent={t<string>("home.help.side-content")}
            buttonAction={{
              buttonLabel: t<string>("home.help.button.label"),
              url: ROUTES.public.summary.generate(
                "/particulier/aides-financieres",
              ),
            }}
            fillContainer
          />
        </div>
        <div className="mb-40 pt-20" />
      </div>
    </PageLayout>
  );
};

export default HomeScreen;
