import { createEffect } from "effector";
import "whatwg-fetch";
import configuration from "@utils/Config";
import {
  restCreationHandler,
  restDeletionHandler,
  restListHandler,
  restPatchHandler,
} from "@utils/rest/RestClient";
import { GazEventActionResponseDto } from "@state/gazMonth/dto/gaz.event.registration.response.dto";
import { GazEventActionSaveRequestDto } from "@state/gazMonth/pro/dto/gazEvent.save.request.dto";

const baseUrl = configuration.backendBaseUrl;
const gazMonthActionUrl = baseUrl + "/gaz-event-actions";

export const requestCreateGazEventAction = createEffect({
  handler: restCreationHandler<
    GazEventActionSaveRequestDto,
    GazEventActionResponseDto
  >(`${gazMonthActionUrl}`),
});
export const requestUpdateGazEventAction = createEffect({
  handler: restPatchHandler<
    GazEventActionSaveRequestDto,
    GazEventActionResponseDto
  >(`${gazMonthActionUrl}/`),
});

export const requestDeleteGazEventAction = createEffect({
  handler: restDeletionHandler<GazEventActionResponseDto>(
    `${gazMonthActionUrl}/`,
  ),
});

export const requestGazEventActionForLandingPage = createEffect({
  handler: restListHandler<GazEventActionResponseDto>(
    `${gazMonthActionUrl}/search/landing_page`,
  ),
});
