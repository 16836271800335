export const partners = [
  // { name: "antargaz", logo: "/img/partners/logo-antargaz.png" },
  { name: "ariston", logo: "/img/partners/logo-ariston.png" },
  { name: "atlantic", logo: "/img/partners/logo-atlantic.png" },
  { name: "best-fires", logo: "/img/partners/logo-best-fires.png" },
  { name: "bosch", logo: "/img/partners/logo-bosch.png" },
  { name: "brotje", logo: "/img/partners/logo-brotje.png" },
  { name: "butagaz", logo: "/img/partners/logo-butagaz.png" },
  { name: "capeb", logo: "/img/partners/logo-capeb.png" },
  { name: "chappee", logo: "/img/partners/logo-chappee.png" },
  { name: "chazelles", logo: "/img/partners/logo-chazelles.png" },
  { name: "daikin", logo: "/img/partners/logo-daikin.png" },
  { name: "dedietrich", logo: "/img/partners/logo-dedietrich.png" },
  { name: "delta-dore", logo: "/img/partners/logo-delta-dore.png" },
  { name: "domusa", logo: "/img/partners/logo-domusa.png" },
  { name: "dru", logo: "/img/partners/logo-dru.png" },
  { name: "elm-leblanc", logo: "/img/partners/logo-elm-leblanc.png" },
  { name: "ffb", logo: "/img/partners/logo-ffb.png" },
  { name: "frisquet", logo: "/img/partners/logo-frisquet.png" },
  { name: "grdf", logo: "/img/partners/logo-grdf.png" },
  { name: "intuis", logo: "/img/partners/logo-intuis.png" },
  // { name: "kal-fire", logo: "/img/partners/logo-kal-fire.png" },
  // { name: "oertli", logo: "/img/partners/logo-oertli.png" },
  { name: "perge", logo: "/img/partners/logo-perge.png" },
  // { name: "prima-gaz", logo: "/img/partners/logo-prima-gaz.png" },
  { name: "saunier-duval", logo: "/img/partners/logo-saunier-duval.png" },
  // { name: "ruegg-studio", logo: "/img/partners/logo-ruegg-studio.png" },
  { name: "synasav", logo: "/img/partners/logo-synasav.png" },
  { name: "thermo-zyklus", logo: "/img/partners/logo-thermo-zyklus.png" },
  { name: "vaillant", logo: "/img/partners/logo-vaillant.png" },
  { name: "viessmann", logo: "/img/partners/logo-viessmann.png" },
  // { name: "vitogaz", logo: "/img/partners/logo-vitogaz.png" },
  { name: "weishaupt", logo: "/img/partners/logo-weishaupt.png" },
];
