import { FunctionComponent, useState } from "react";
import InputFormField from "@components/inputs/InputFormField";
import SubmitButton from "@components/buttons/SubmitButton";
import { Link, useNavigate } from "react-router-dom";
import { Form } from "antd";
import { FieldData, Store } from "rc-field-form/lib/interface";
import { requestGetUserInfo, requestLogin } from "@state/auth/AuthEffects";
import { ParsedResponse } from "@utils/rest/ServerResponseParse";
import { AuthResult } from "@type/auth/auth.types";
import { setUser } from "@state/auth/AuthEvents";
import { formHelper } from "@utils/form-helper";
import { useTranslation } from "react-i18next";
import { formItemBlockLayout } from "@utils/Constant";
import TagManager from "react-gtm-module";
import { toastError } from "@utils/toast-helper";
import { ROUTES } from "@utils/Routes";

interface LoginFormProps {
  proHome?: boolean;
}

interface FormValues {
  username: string;
  password: string;
}

const LoginForm: FunctionComponent<LoginFormProps> = ({ proHome = false }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [fields, setFields] = useState<FieldData[]>([]);

  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const InitialValues = {
    login: "",
    password: "",
  };

  const handleSubmit = (values: Store) => {
    setButtonLoading(true);
    const obj = values as FormValues;

    requestLogin({
      dto: {
        username: obj.username,
        password: obj.password,
      },
    })
      .then((authResult: ParsedResponse<AuthResult>) => {
        if (authResult.data) {
          void requestGetUserInfo({}).then((authResult) => {
            if (authResult.ok && authResult.data) {
              setUser(authResult.data);
              navigate(ROUTES.professional.connectedHome.generate());
            }
          });
        } else if (authResult.fieldErrors) {
          setFields(
            formHelper.addErrorsToFieldsData([], authResult.fieldErrors),
          );
        }
      })
      .catch(() => {
        toastError(t<string>("login.messages.error"));
      })
      .finally(() => setButtonLoading(false));
  };

  return (
    <Form
      className={proHome ? "pro-home-form" : ""}
      onFinish={handleSubmit}
      initialValues={InitialValues}
      fields={fields}
      {...formItemBlockLayout}
    >
      <InputFormField
        module="login.gazProfessional"
        field="username"
        rules={[
          {
            required: true,
            pattern: new RegExp(/^[A-Z]{2}\d{4}/i),
            message: t<string>(
              "login.gazProfessional.form.fields.username.errors.invalid",
            ),
          },
        ]}
      />
      <InputFormField
        module="login.gazProfessional"
        type="password"
        field="password"
        required
      />
      {proHome ? (
        <>
          <div className="d-flex flex-column flex-sm-row align-items-center justify-content-center pro-home-form-buttons justify-content-sm-start">
            <SubmitButton
              module="login.gazProfessional"
              isSubmitting={buttonLoading}
              label="submit"
              size="lg"
              className="me-sm-4 mb-4 mb-sm-0"
              onClick={() => {
                TagManager.dataLayer({
                  dataLayer: {
                    event: "se_connecter_home_pro",
                  },
                });
              }}
            />
            <Link to={ROUTES.professional.auth.registration.generate()}>
              <span className="btn btn-link">
                {t<string>("pro.home.header.left.join")}
              </span>
            </Link>
          </div>
          <div className="mt-2">
            <Link
              to={ROUTES.professional.auth.forgotPassword.generate()}
              className="pro-home-form-buttons"
            >
              <span className="btn btn-link">
                {t<string>(
                  "login.gazProfessional.form.actions.forgot-password",
                )}
              </span>
            </Link>
          </div>
          <div className="mt-2">
            <Link
              to={ROUTES.professional.auth.loginOp.generate()}
              className="pro-home-form-buttons"
            >
              <span className="btn btn-link">
                {t<string>(
                  "login.gazProfessional.form.actions.organisationProfessional",
                )}
              </span>
            </Link>
          </div>
        </>
      ) : (
        <>
          <div className="d-flex flex-column align-items-center justify-content-center my-3">
            <Link to={ROUTES.professional.auth.registration.generate()}>
              <span className="btn btn-link pl-0">
                {t<string>("login.gazProfessional.form.actions.registration")}
              </span>
            </Link>
            <Link to={ROUTES.professional.auth.forgotPassword.generate()}>
              <span className="btn btn-link pl-0">
                {t<string>(
                  "login.gazProfessional.form.actions.forgot-password",
                )}
              </span>
            </Link>
          </div>
          <div className="d-flex align-items-center justify-content-center mb-3">
            <SubmitButton
              module="login.gazProfessional"
              isSubmitting={buttonLoading}
              label="submit"
            />
          </div>
          <div className="d-flex align-items-center justify-content-center mb-3">
            <Link to={ROUTES.professional.auth.loginOp.generate()}>
              <span className="btn btn-link pl-0">
                {t<string>(
                  "login.gazProfessional.form.actions.organisationProfessional",
                )}
              </span>
            </Link>
          </div>
        </>
      )}
    </Form>
  );
};

export default LoginForm;
