import { createRef, FC, Ref, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BasicCard from "@components/cards/BasicCard";
import parse from "html-react-parser";
import { formItemBlockLayout } from "@utils/Constant";
import { Carousel, Col, Form, Row } from "antd";
import { useStore } from "effector-react";
import { authentifiedStore } from "@state/auth/AuthStore";
import Section from "@components/section/Section";
import SubmitButton from "@components/buttons/SubmitButton";
import { Store } from "rc-field-form/lib/interface";
import { useForm } from "antd/lib/form/Form";
import BasicModal from "@components/modals/BasicModal";
import BasicButton from "@components/buttons/BasicButton";
import GazEventActionCard from "@components/cards/GazEventActionCard";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { GazEventActionSaveRequestDto } from "@state/gazMonth/pro/dto/gazEvent.save.request.dto";
import {
  requestCreateGazEventAction,
  requestDeleteGazEventAction,
  requestUpdateGazEventAction,
} from "@state/gazMonth/pro/GazEventActionEffect";
import GazEventActionForm from "@components/forms/GazEventActionForm";
import {
  ACTION_TYPE,
  ActionType,
} from "@utils/enums/gaz.month.action.type.enum";
import { Link, useLocation } from "react-router-dom";
import { CarouselRef } from "antd/lib/carousel";
import {
  addGazEventAction,
  deleteGazEventAction,
  updateGazEventAction,
} from "@state/auth/AuthEvents";
import EmbedVideoModal from "@components/modals/EmbedVideoModal";
import { GazProfessionalUser } from "@type/auth/auth.types";
import { requestGetResources } from "@state/resource/ResourceEffects";
import ToolsCard from "@components/cards/ToolsCard";
import { mapResourceTofileType } from "@utils/upload-utils";
import { File } from "@type/resource-type-interface";
import { downloadFromFile } from "@utils/Base64File";
import { toastError, toastSuccess } from "@utils/toast-helper";
import { GazEventState } from "@type/gazEvent/GazEventState";
import { ROUTES } from "@utils/Routes";
import configuration from "@utils/Config";
import { GazEventActionResponseDto } from "@state/gazMonth/dto/gaz.event.registration.response.dto";
import {
  PAC_HYBRIDE_PARTNER,
  POELE_PARTNER,
  REGULATION_PARTNER,
  THPE_PARTNER,
} from "@utils/enums/gaz.month.action.amount.enum";

const GazEventActionScreen: FC = () => {
  const { t } = useTranslation();
  const authentifiedContext = useStore(authentifiedStore);
  const userPg = authentifiedContext.user as GazProfessionalUser;
  const location = useLocation();
  const gazEventType = configuration.gazEventType;

  const [isTutorialModalVisible, setIsTutorialModalVisible] = useState<boolean>(
    (location.state as GazEventState)?.fromRegistration || false,
  );
  const [actionForm] = useForm();
  const [isKnowMoreModalVisible, setIsKnowMoreModalVisible] =
    useState<boolean>(false);
  const carouselRef: Ref<CarouselRef> = createRef();

  useEffect(() => {
    void requestGetResources({
      dto: {
        domain: ["SYNTHESIS_OFFER"],
        ids: [],
      },
    });
  }, []);

  const [synthesisOfferFiles, setSynthesisOfferFiles] = useState<File[]>([]);

  useEffect(() => {
    return requestGetResources.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setSynthesisOfferFiles(
          result.data.map((resource) => {
            return mapResourceTofileType(resource);
          }),
        );
      }
    });
  });

  const handleValue = (values: Store, actionType: string) => {
    const amount = (values[`${actionType}.amount`] as string) ?? null,
      description = (values[`${actionType}.description`] as string) ?? null,
      otherAmount = (values[`${actionType}.otherAmount`] as string) ?? null,
      partner = (values[`${actionType}.partner`] as string[]) ?? null;

    const actionToSave: GazEventActionSaveRequestDto = {
      actionType: actionType.toLocaleUpperCase(),
      amount: amount,
      description: description,
      otherAmount: otherAmount,
      partner: partner,
    };
    return actionToSave;
  };

  const setActionToSave = (gazEventAction: GazEventActionSaveRequestDto) => {
    if (
      gazEventAction.amount !== "NO_OFFER" &&
      gazEventAction.amount !== null
    ) {
      handleSaveAction(gazEventAction);
    }
  };

  const getOptionPartner = (action: ActionType) => {
    switch (action) {
      case ACTION_TYPE.PAC:
        return PAC_HYBRIDE_PARTNER;
      case ACTION_TYPE.THPE:
        return THPE_PARTNER;
      case ACTION_TYPE.POELE:
        return POELE_PARTNER;
      case ACTION_TYPE.REGULATION:
        return REGULATION_PARTNER;
      default:
        return PAC_HYBRIDE_PARTNER;
    }
  };

  const handleActionSubmit = (values: Store) => {
    const pac = handleValue(values, "pac");
    const thpe = handleValue(values, "thpe");
    const poele = handleValue(values, "poele");
    const regulation = handleValue(values, "regulation");

    setActionToSave(pac);
    setActionToSave(thpe);
    setActionToSave(poele);
    setActionToSave(regulation);
  };

  const handleSaveAction = (actionToSave: GazEventActionSaveRequestDto) => {
    if (actionToSave) {
      void requestCreateGazEventAction({
        dto: actionToSave,
      });
    }
  };

  useEffect(() => {
    return requestUpdateGazEventAction.done.watch(({ result }) => {
      if (result.ok && result.data) {
        updateGazEventAction(result.data);
        actionForm.resetFields();
        toastSuccess(
          t<string>(`${gazEventType}.pro.subscribed.actions.updated.success`),
        );
      } else {
        toastError(result.errorMessage);
      }
    });
  });

  useEffect(() => {
    return requestCreateGazEventAction.done.watch(({ result }) => {
      if (result.ok && result.data) {
        addGazEventAction(result.data);
        actionForm.resetFields();
        toastSuccess(
          t<string>(`${gazEventType}.pro.subscribed.actions.created.success`),
        );
      } else {
        toastError(result.errorMessage);
      }
    });
  });

  const actions = userPg?.gazEvent?.actions ?? [];
  const sortList = ["PAC", "THPE", "POELE", "REGULATION"];

  return (
    <>
      <h1 className="mt-30">
        {t<string>(`${gazEventType}.pro.subscribed.title`)}
      </h1>
      <span>{parse(t<string>(`${gazEventType}.pro.subscribed.subtitle`))}</span>
      {authentifiedContext.user && (
        <>
          <div className="d-flex align-items-center flex-wrap mb-3">
            <div
              onClick={() => setIsTutorialModalVisible(true)}
              className="btn-primary-link mb-4"
            >
              {t<string>(
                `${gazEventType}.pro.subscribed.addAction.buttons.tutorial`,
              )}
            </div>
          </div>
          {actions.length < 4 && (
            <BasicCard className="mb-40">
              <div className="px-12">
                <div>
                  <Row>
                    <Col xs={24} lg={14}>
                      <h3 className="text-primary">
                        {t<string>(
                          `${gazEventType}.pro.subscribed.addAction.card.title`,
                        )}
                      </h3>
                    </Col>
                    <div className="mb-30">
                      {parse(
                        t<string>(
                          `${gazEventType}.pro.subscribed.addAction.card.detail`,
                        ),
                      )}
                    </div>
                    <div className="mb-30">
                      {t<string>(
                        `${gazEventType}.pro.subscribed.addAction.card.listTitle`,
                      )}
                      <ul>
                        <li>
                          {t<string>(
                            `${gazEventType}.pro.subscribed.addAction.card.listItem1`,
                          )}
                        </li>
                        <li>
                          {t<string>(
                            `${gazEventType}.pro.subscribed.addAction.card.listItem2`,
                          )}
                        </li>
                        <li>
                          {t<string>(
                            `${gazEventType}.pro.subscribed.addAction.card.listItem3`,
                          )}
                        </li>
                        <li>
                          {parse(
                            t<string>(
                              `${gazEventType}.pro.subscribed.addAction.card.listItem4`,
                            ),
                          )}
                        </li>
                      </ul>
                    </div>
                    {synthesisOfferFiles.length !== 0 && (
                      <div className="mb-30">
                        {t<string>(
                          `${gazEventType}.pro.subscribed.addAction.card.knowMore`,
                        )}
                        <a
                          className="text-primary"
                          onClick={() =>
                            downloadFromFile(synthesisOfferFiles[0])
                          }
                        >
                          {t<string>(
                            `${gazEventType}.pro.subscribed.addAction.card.linkTitle`,
                          )}
                        </a>
                      </div>
                    )}
                  </Row>
                </div>
                <Form
                  onFinish={handleActionSubmit}
                  form={actionForm}
                  {...formItemBlockLayout}
                >
                  {Object.values(ACTION_TYPE)
                    .filter(
                      (action) =>
                        !actions
                          .map(
                            (type: GazEventActionResponseDto) =>
                              type.actionType,
                          )
                          .includes(action.value),
                    )
                    .map((type, index) => (
                      <div key={index.valueOf()}>
                        <GazEventActionForm actionType={type} />
                      </div>
                    ))}
                  <div className="d-flex align-items-center justify-content-center">
                    <SubmitButton
                      module={`${gazEventType}.pro.subscribed.addAction.pac`}
                      isSubmitting={false}
                      label="submit"
                      className="mb-20 ms-4"
                    />
                  </div>
                </Form>
              </div>
            </BasicCard>
          )}
          <Section
            type="h2"
            title={t<string>(`${gazEventType}.pro.subscribed.actions.title`)}
            titleClassName="pt-20"
          >
            <>
              <div className="mb-30">
                {actions && actions.length > 0 ? (
                  <>
                    {t<string>(
                      `${gazEventType}.pro.subscribed.actions.registered.subtitle1`,
                    )}
                    <br />
                  </>
                ) : (
                  <>
                    {t<string>(
                      `${gazEventType}.pro.subscribed.actions.notRegistered.subtitle`,
                    )}
                  </>
                )}
              </div>
              {actions
                .sort((a, b) => {
                  return (
                    sortList.indexOf(a.actionType) -
                    sortList.indexOf(b.actionType)
                  );
                })
                .map((action, index) => (
                  <GazEventActionCard
                    id={action.id}
                    key={index.valueOf()}
                    actionType={ACTION_TYPE[action.actionType]}
                    title={action.title
                      .concat(": ")
                      .concat(
                        action.partner
                          .map(
                            (part) =>
                              Object.values(
                                getOptionPartner(
                                  ACTION_TYPE[action.actionType],
                                ),
                              ).filter((p) => p.value === part)[0].label,
                          )
                          .join(", "),
                      )}
                    amount={action.amount}
                    otherAmount={action.otherAmount}
                    actionFrom={action.actionFrom}
                    actionTo={action.actionTo}
                    partner={action.partner}
                    description={
                      action.description
                        ? action.description
                        : t<string>(
                            `${gazEventType}.individual.professional.actions.description`,
                          )
                    }
                    onDelete={() =>
                      void requestDeleteGazEventAction({ dto: action }).then(
                        (result) => {
                          if (result.ok) {
                            deleteGazEventAction(action.id);
                            toastSuccess(
                              t<string>(
                                `${gazEventType}.pro.subscribed.actions.modals.delete.success`,
                              ),
                            );
                          } else {
                            toastError(result.errorMessage);
                          }
                        },
                      )
                    }
                    className="mb-20"
                  />
                ))}
            </>
            <div className="d-flex justify-content-center pt-20">
              <Link
                to={ROUTES.public.gazProfessionals.details.generate(
                  userPg.yoocanId,
                )}
              >
                <BasicButton
                  variant="primary"
                  text={t<string>(
                    `${gazEventType}.pro.subscribed.actions.registered.button`,
                  )}
                />
              </Link>
            </div>
          </Section>
          <Section
            type="h2"
            title={t<string>(`${gazEventType}.pro.subscribed.cards.title`)}
            titleClassName="pt-20"
          >
            <div className="d-flex flex-column-reverse flex-md-row justify-content-center pt-20">
              <ToolsCard
                icon={
                  <img
                    alt={t<string>(
                      `${gazEventType}.pro.subscribed.cards.alt.gazbox`,
                    )}
                    src="/img/pro/hotline.svg"
                  />
                }
                title={t<string>(`${gazEventType}.pro.subscribed.cards.gazbox`)}
                description={t<string>(
                  `${gazEventType}.pro.subscribed.cards.gazboxDescription`,
                )}
                buttonText={t<string>(
                  `${gazEventType}.pro.subscribed.cards.gazboxButton`,
                )}
                onClick={() => {
                  window.open(
                    "https://lesprofessionnelsdugaz.com/professionnel/gazbox-loutil-pour-faciliter-la-creation-de-vos-supports-commerciaux",
                  );
                }}
              />
              {synthesisOfferFiles.length !== 0 && (
                <ToolsCard
                  icon={
                    <img
                      alt={t<string>(
                        `${gazEventType}.pro.subscribed.cards.alt.offer`,
                      )}
                      src="/img/pro/offer.svg"
                    />
                  }
                  title={t<string>(
                    `${gazEventType}.pro.subscribed.cards.offer`,
                  )}
                  description={t<string>(
                    `${gazEventType}.pro.subscribed.cards.offerDescription`,
                  )}
                  buttonText={t<string>(
                    `${gazEventType}.pro.subscribed.cards.offerButton`,
                  )}
                  onClick={() => downloadFromFile(synthesisOfferFiles[0])}
                />
              )}
            </div>
          </Section>
        </>
      )}
      <BasicModal
        open={isTutorialModalVisible}
        onCancel={() => setIsTutorialModalVisible(false)}
        footer={null}
        className="modal-tutorial-gaz-month"
      >
        <>
          <Carousel className="tutorial-carousel" ref={carouselRef}>
            <div className="tutorial-slide">
              <h3 className="text-primary mb-0">
                {t<string>(
                  `${gazEventType}.pro.subscribed.tutorial.slides.slide1.header`,
                )}
              </h3>
              <div className="tutorial-slide-title">
                {t<string>(
                  `${gazEventType}.pro.subscribed.tutorial.slides.slide1.title`,
                )}
              </div>
              <div className="tutorial-slide-subtitle">
                {parse(
                  t<string>(
                    `${gazEventType}.pro.subscribed.tutorial.slides.slide1.subtitle`,
                  ),
                )}
              </div>
              <div className="tutorial-slide-img d-flex justify-content-center">
                <img
                  src="/img/pro/tutorial-temps-forts-slide1.png"
                  alt={t<string>(
                    `${gazEventType}.pro.subscribed.tutorial.slides.slide1.alt`,
                  )}
                />
              </div>
            </div>
            <div className="tutorial-slide">
              <h3 className="text-primary mb-0">
                {t<string>(
                  `${gazEventType}.pro.subscribed.tutorial.slides.slide2.header`,
                )}
              </h3>
              <div className="tutorial-slide-title">
                {t<string>(
                  `${gazEventType}.pro.subscribed.tutorial.slides.slide2.title`,
                )}
              </div>
              <div className="tutorial-slide-subtitle">
                {parse(
                  t<string>(
                    `${gazEventType}.pro.subscribed.tutorial.slides.slide2.subtitle`,
                  ),
                )}
              </div>
              <div className="tutorial-slide-img d-flex justify-content-center">
                <img
                  src="/img/pro/tutorial-temps-forts-slide2.png"
                  alt={t<string>(
                    `${gazEventType}.pro.subscribed.tutorial.slides.slide2.alt`,
                  )}
                />
              </div>
            </div>
            <div className="tutorial-slide">
              <h3 className="text-primary mb-0">
                {t<string>(
                  `${gazEventType}.pro.subscribed.tutorial.slides.slide3.header`,
                )}
              </h3>
              <div className="tutorial-slide-title">
                {t<string>(
                  `${gazEventType}.pro.subscribed.tutorial.slides.slide3.title`,
                )}
              </div>
              <div className="tutorial-slide-img d-flex justify-content-center">
                <img
                  src="/img/pro/tutorial-temps-forts-slide3.png"
                  alt={t<string>(
                    `${gazEventType}.pro.subscribed.tutorial.slides.slide3.alt`,
                  )}
                />
              </div>
            </div>
            <div className="tutorial-slide">
              <h3 className="text-primary mb-0">
                {t<string>(
                  `${gazEventType}.pro.subscribed.tutorial.slides.slide4.header`,
                )}
              </h3>
              <div className="tutorial-slide-title">
                {t<string>(
                  `${gazEventType}.pro.subscribed.tutorial.slides.slide4.title`,
                )}
              </div>
              <div className="tutorial-slide-subtitle">
                {parse(
                  t<string>(
                    `${gazEventType}.pro.subscribed.tutorial.slides.slide4.subtitle`,
                  ),
                )}
              </div>
              <div className="tutorial-slide-img d-flex justify-content-center mt-20">
                <img
                  src="/img/pro/tutorial-temps-forts-slide4.png"
                  alt={t<string>(
                    `${gazEventType}.pro.subscribed.tutorial.slides.slide4.alt`,
                  )}
                />
              </div>
            </div>
          </Carousel>
          <div className="d-flex align-items-center justify-content-center">
            <BasicButton
              text={""}
              variant="primary"
              className="mt-2 tutorial-carousel-button mx-4"
              icon={<LeftOutlined />}
              onClick={() => carouselRef?.current?.prev()}
            />
            <BasicButton
              text={""}
              variant="primary"
              className="mt-2 tutorial-carousel-button mx-4"
              icon={<RightOutlined />}
              onClick={() => carouselRef?.current?.next()}
            />
          </div>
        </>
      </BasicModal>

      <EmbedVideoModal
        open={isKnowMoreModalVisible}
        onClose={() => {
          setIsKnowMoreModalVisible(false);
        }}
        title={t<string>(
          `${gazEventType}.pro.notSubscribed.card.knowMore.modal`,
        )}
        videoUrl="https://vimeo.com/669359116"
      />
    </>
  );
};

export default GazEventActionScreen;
