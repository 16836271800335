import { Col, Row } from "antd";
import TextAreaFormField from "@components/inputs/TextAreaFormField";

import { FunctionComponent, useState } from "react";
import {
  ACTION_TYPE,
  ActionType,
} from "@utils/enums/gaz.month.action.type.enum";
import { useTranslation } from "react-i18next";
import { SelectFormField } from "@components/inputs/SelectFormField";
import InputNumberFormField from "@components/inputs/InputNumberFormField";
import { GazEventActionFormValues } from "@components/cards/GazEventActionCard";
import configuration from "@utils/Config";
import { getAmountOption, getOptionPartner } from "@utils/gazEvent-utils";

interface GazEventActionFormProps {
  actionType: ActionType;
  initialValue?: GazEventActionFormValues;
}

const GazEventActionForm: FunctionComponent<GazEventActionFormProps> = ({
  actionType,
  initialValue,
}) => {
  const { t } = useTranslation();
  const [amount, setAmount] = useState<string>(initialValue?.amount ?? "");
  const gazEventType = configuration.gazEventType;

  const getTitle = (action: ActionType) => {
    switch (action) {
      case ACTION_TYPE.PAC:
        return t<string>(`${gazEventType}.pro.subscribed.addAction.pac.title`);
      case ACTION_TYPE.THPE:
        return t<string>(`${gazEventType}.pro.subscribed.addAction.thpe.title`);
      case ACTION_TYPE.POELE:
        return t<string>(
          `${gazEventType}.pro.subscribed.addAction.poele.title`,
        );
      case ACTION_TYPE.REGULATION:
        return t<string>(
          `${gazEventType}.pro.subscribed.addAction.regulation.title`,
        );
      default:
        return "Pas d'offre";
    }
  };

  const onSelect = (value: string) => {
    value && setAmount(value);
  };

  return (
    <>
      <div className="mb-10">{getTitle(actionType)}</div>
      <Row gutter={16}>
        <Col xs={24} lg={12}>
          <SelectFormField
            showLabel
            module={`${gazEventType}.pro.subscribed.addAction.${actionType.value.toLowerCase()}`}
            field={`${actionType.value.toLowerCase()}.amount`}
            options={
              initialValue
                ? Object.values(getAmountOption(actionType)).filter(
                    (action) => action.value !== "NO_OFFER",
                  )
                : Object.values(getAmountOption(actionType))
            }
            onSelect={onSelect}
            initialValue={initialValue && amount}
            required
          />
        </Col>
        {amount !== "NO_OFFER" && (
          <Col xs={24} lg={12}>
            <SelectFormField
              mode="multiple"
              showLabel
              module={`${gazEventType}.pro.subscribed.addAction.${actionType.value.toLowerCase()}`}
              field={`${actionType.value.toLowerCase()}.partner`}
              options={getOptionPartner(actionType)}
              initialValue={initialValue?.partner}
              onChange={(value) => {
                if (value?.length > 3) {
                  const newTabValue = value as string[];
                  newTabValue.pop();
                }
              }}
              required
              showSearch
            />
          </Col>
        )}

        {amount === "OTHER_AMOUNT" && (
          <Col xs={24} lg={12}>
            <InputNumberFormField
              className={"gazMonth-otherAmount"}
              module={`${gazEventType}.pro.subscribed.addAction.${actionType.value.toLowerCase()}`}
              field={`${actionType.value.toLowerCase()}.otherAmount`}
              min={50}
              max={15000}
              step={1}
              showLabel
              required
              precision={0}
              initialValue={initialValue?.otherAmount}
            />
          </Col>
        )}
        <Col xs={24}>
          <TextAreaFormField
            showLabel
            module={`${gazEventType}.pro.subscribed.addAction.${actionType.value.toLowerCase()}`}
            field={`${actionType.value.toLowerCase()}.description`}
            initialValue={initialValue?.description}
          />
        </Col>
      </Row>
    </>
  );
};

export default GazEventActionForm;
